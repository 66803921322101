<template>
  <v-dialog
    id="form_view"
    v-if="bActive && (myReport !== null)"
    overlay-color="primary"
    overlay-opacity=".97"
    persistent
    :fullscreen="smUp ? false: true"
    max-width="60%"
    v-model="bActive">
    <v-card
      :shaped="smUp"
      class="dialog_card_container">
      <!-- Start close icon -->
      <v-layout
        row
        justify-end>
        <v-btn
          style="margin-right: 10px"
          icon
          fab
          small
          color="grey"
          text
          @click="close">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-layout>
      <!-- End close icon -->
      <!-- Start dialog title -->
      <v-container
        style="margin-top: -30px">
        <v-layout
          row
          justify-center>
          <p
            v-text="dialog_title"
            class="dialog_title_text"
          />
        </v-layout>
      </v-container>
      <!-- End dialog title -->
      <!-- Start form containr -->
      <!-- Start form containr -->
      <v-container>
        <v-layout
          row
          justify-center>
          <v-layout
            column
            :align-center="!smUp"
            style="max-width: 80%">
            <!-- Start name textfield -->
            <v-layout
              row
              :justify-start="smUp"
              :justify-center="!smUp">
              <p
                class="field_texts"
                v-text="name_text"
              />
            </v-layout>
            <v-layout
              row
              justify-center
              class="field_layout">
              <v-text-field 
                v-model="myReport.sTitle"
                color="primary"
                :placeholder="name_text"
              />
            </v-layout>
            <!-- End name textfield -->
            <!-- Start phone textfield -->
            <v-layout
              row
              :justify-start="smUp"
              :justify-center="!smUp">
              <p
                class="field_texts"
                v-text="yearText"
              />
            </v-layout>
            <v-layout
              row
              justify-center
              class="field_layout">
              <v-select
                style="margin-top: 20px"
                :items="yearOptions"
                v-model="myReport.iYear"
                label="Year"
                class="customPlaceHolder"
                clearable>
                <template v-slot:no-data>
                  <p
                    class="selectNoData">
                    No years available for selection.
                  </p>
                </template>
                <template 
                  slot="item" 
                  slot-scope="data">
                  <v-layout
                    row
                    justify-center>
                    <p class="selectItem">
                      {{data.item}}
                    </p>
                  </v-layout>
                </template>
              </v-select>
            </v-layout>
            <!-- End phone textfield -->
            <!-- Start mail textfield -->
            <v-layout
              row
              :justify-start="smUp"
              :justify-center="!smUp">
              <p
                class="field_texts"
                v-text="monthText"
              />
            </v-layout>
            <v-layout
              row
              justify-center
              class="field_layout">
              <v-select
                style="margin-top: 20px"
                :items="monthOptions"
                v-model="myReport.iMonth"
                label="Month"
                class="customPlaceHolder"
                clearable>
                <template v-slot:no-data>
                  <p
                    class="selectNoData">
                    No months available for selection.
                  </p>
                </template>
                <template 
                  slot="item" 
                  slot-scope="data">
                  <v-layout
                    row
                    justify-center>
                    <p class="selectItem">
                      {{data.item.text}}
                    </p>
                  </v-layout>
                </template>
              </v-select>
            </v-layout>
            <!-- End mail textfield -->
            <v-layout
              row
              justify-center>
              <v-card 
               style="width: 100%; padding: 10px;">
                <v-data-table
                  hide-default-footer
                  :headers="pdfHeaders"
                  :items="myReport.sPdfKeys"
                  :items-per-page="20">
                  <template 
                    v-slot:item="props">
                    <tr
                      v-if="smUp"
                      class="application_table_row">
                      <td
                        class="text-left">
                        <p 
                          v-html="props.item"
                          class="text_content"
                        />
                      </td>
                      <td
                        class="text-left">
                        <v-btn 
                          icon 
                          fab 
                          small 
                          @click="setPdf(props.item)">
                          <v-icon>mdi-download</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-layout>
            <v-layout
              style="padding-top: 20px"
              row
              wrap
              align-center>
              <v-flex
                pa-5
                xs8
                sm8
                md8>
                <v-file-input
                  style="width: 100%; cursor: pointer !important"
                  v-model="newFile"
                  clearable
                  label="Report"
                  append-icon="mdi-alert">
                </v-file-input>
              </v-flex>
              <v-flex
                pa-5
                xs4
                sm4
                md4>
                <v-select
                  :items="[{text: 1, value: 0}, {text: 2, value: 1}, {text: 3, value: 2}]"
                  style="width: 100%; cursor: pointer !important;"
                  v-model="positionToReplace"
                  clearable
                  label="Position to place pdf in"
                  append-icon="mdi-autorenew">
                </v-select>
              </v-flex>
              <v-flex
                class="text-center"
                xs12
                sm12
                md12>
                <v-btn
                  color="primary"
                  dense
                  :loading="load_edit_btn"
                  :disabled="newFile == null"
                  @click="addReport()">
                  Add Report
                </v-btn>
              </v-flex>
            </v-layout>
          </v-layout>
        </v-layout>
      </v-container>
      <!-- End form containr -->
      <!-- End form containr -->
      <v-container>
        <!-- Center button layout -->
        <v-layout
          row
          justify-center>
          <!-- Limit button layout extension -->
          <v-layout
            row
            style="max-width: 80%">
            <v-btn
              color="red"
              text
              @click="close">
              {{cancel_btn_txt}}
            </v-btn>
            <v-spacer>
            </v-spacer>
            <v-btn
              color="primary"
              dense
              :loading="load_edit_btn"
              :disabled="load_edit_btn"
              @click="confirm_edit">
              {{submit_btn_txt}}
            </v-btn>
          </v-layout>
          <!-- Limit button layout extension -->
        </v-layout>
        <!-- Center button layout  -->
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    pdfHeaders: [
      { text: 'Name' },
      { text: 'Actions' },
    ],
    positionToReplace: 0,
    myReport: null,
    name_text: 'Title',
    yearText: 'Year',
    monthText: 'Month',
    password_text: 'Password',
    confirm_password_text: 'Confirm Password',
    submit_btn_txt: 'Save',
    cancel_btn_txt: 'Cancel',
    dialog_title: 'Edit report',
    load_edit_btn: false,
    yearOptions: [],
    newFile: null,
    monthOptions: [
      {
        text: 'January',
        value: 0,
      },
      {
        text: 'February',
        value: 1,
      },
      {
        text: 'March',
        value: 2,
      },
      {
        text: 'April',
        value: 3,
      },
      {
        text: 'May',
        value: 4,
      },
      {
        text: 'June',
        value: 5,
      },
      {
        text: 'July',
        value: 6,
      },
      {
        text: 'August',
        value: 7,
      },
      {
        text: 'September',
        value: 8,
      },
      {
        text: 'October',
        value: 9,
      },
      {
        text: 'November',
        value: 10,
      },
      {
        text: 'December',
        value: 11,
      }
    ],
  }),
  mounted () {
    this.fillYears()
  },
  watch: {
    bActive: function () {
      if (this.bActive) {
        this.getReport()
      } 
    }
  },
  props: {
    bActive: Boolean,
    report_to_edit: Object
  },
  methods: {
    addReport () {
      // var file = new FormData()
      // file.append('file', this.newFile)
      
      var reportFile = new FormData()

      reportFile.append('file', this.newFile)

      var reportID = this.report_to_edit._id
      db.put(`${uri}/v2/api/reports/` + reportID + '/pdfs', reportFile, { //  file, {
        headers: {
          'Authorization': this.usr_token,
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        params: {
          position: this.positionToReplace
        }
      })
      .then(resp => {
        this.getReport()
        this.newFile = null
        const alert = {
          color: 'success',
          text: resp.data.message 
        }
        this.$store.commit('toggle_alert', alert)
      })
      .catch(err => {
        const alert = {
          color: 'fail',
          text: err.response.data.message 
        }
        this.$store.commit('toggle_alert', alert)
      })
    },
    setPdf (item) {
      var url = this.myReport.urls[this.myReport.sPdfKeys.indexOf(item)]
      window.open(url,'_blank')
    },
    fillYears () {
      var currentYear = new Date().getFullYear()
      var startYear = 2020
      while ( startYear <= currentYear ) {
          this.yearOptions.push(startYear++);
      }   
    },
    close () {
      this.$emit('close')
    },
    getReport () {
      this.bLoading = true
      db.get(`${uri}/v2/api/reports/` + this.report_to_edit._id, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
          'Authorization': this.usr_token
        }
      })
      .then(resp => {
        this.bLoading = false
        this.$store.commit('toggle_alert', {color: 'success', text: resp.data.message})
        this.myReport = resp.data.report
      })
      .catch(err => {
        this.bLoading = false
        this.$store.commit('toggle_alert', {color: 'fail', text: err.response.data.message})
      })
    },
    confirm_edit () {
      this.load_edit_btn = true
      var report_update = {
        sTitle: this.report_to_edit.sTitle,
        iYear: this.report_to_edit.iYear,
        iMonth: this.report_to_edit.iMonth,
      }
      var body = encode.encodeJSON(report_update)
      var reportID = this.report_to_edit._id
      db.put(`${uri}/v2/api/reports/` + reportID, body, {
        headers: {
          'Authorization': this.usr_token,
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
        }
      })
      .then(resp => {
        this.load_edit_btn = false
        const alert = {
          color: 'success',
          text: resp.data.message 
        }
        this.$store.commit('toggle_alert', alert)
        this.close()
        this.myReport = null
        this.$emit('update')
      })
      .catch(err => {
         this.load_edit_btn = false
        const alert = {
          color: 'fail',
          text: err.response.data.message 
        }
        this.$store.commit('toggle_alert', alert)
      })
    },
  },
  computed: {
    usr_token () {
      return 'Bearer ' + this.$store.state.token
    },
    smUp () {
      return this.$vuetify.breakpoint.smAndUp
    }
  }
}
</script>
<style scoped>
  .field_texts {
    margin-bottom: 0px;
    font-family: 'Open sans', sans-serif;
    color: 'black';
    font-size: 12px;
    letter-spacing: .1px;
  }
  .password_layout {
    width: 100%; 
    margin-bottom: 10px
  }
  .dialog_card_container {
    padding: 5%
  }
</style>